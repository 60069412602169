import { Component, OnInit, Input } from '@angular/core';
import { LaCascadaProductService } from '../../services/la-cascada-product.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/la-cascada/logo-white.svg' // Default Logo
  @Input() newsletter: boolean = true; // Default True

  public today: number = Date.now();

  constructor(private laCascadaProductService: LaCascadaProductService, public router: Router) { }

  ngOnInit(): void {
  }

  // changeBrand(brandCode: number){
  //   this.laCascadaProductService.changeBrand(brandCode);
  //   this.router.navigate(['/home/products']);
  // }

}
