import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../classes/product';
import { NewProductSlider } from '../../data/slider';
import { ProductService } from '../../services/product.service';
import { LaCascadaProductService } from '../../services/la-cascada-product.service';
import { ProductLaCascada } from '../../classes/product-la-cascada';

@Component({
  selector: 'app-la-cascada-product-box-vertical-slider',
  templateUrl: './la-cascada-product-box-vertical-slider.component.html',
  styleUrls: ['./la-cascada-product-box-vertical-slider.component.scss']
})
export class LaCascadaProductBoxVerticalSliderComponent implements OnInit {

  @Input() title: string = 'New Product'; // Default

  get productXcategorySlider(): any {
    return this.laCascadaProductService.productXcategorySlider;
  }

  public NewProductSliderConfig: any = NewProductSlider;

  constructor(private laCascadaProductService: LaCascadaProductService) { 
    this.laCascadaProductService.getProductXCityXCategory(this.laCascadaProductService.citySelected[0], '1', 'slider')
  }

  get favorites(): any[] {
    return this.laCascadaProductService.favorites;
  }

  ngOnInit(): void {
  }


}
