import { Component, OnInit } from '@angular/core';
import { LaCascadaProductService } from '../../services/la-cascada-product.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order-confirmation',
  template: `
  <section class="section-b-space card-default">
    <div class="container">
        <div class="row mb-1 mt-1">
            <div class="col-md-12">
                <div class="success-text">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                    <h2>Confirmación de pago</h2>
                    <!-- <p class="mb-1" style="text-transform: unset;">Elf-row pago se procesó con éxito.</p> -->
                    <!-- <div class="box-detail">
                        <p style="text-transform: unset;">Su solicitud de pedido fue realizado con éxito, por favor proceda con el pago según el método seleccionado para finalizar el pedido.</p>
                        <p style="text-transform: unset;">Se ha enviado un recibo por correo electrónico ({{ userInformation.email }}).</p>
                    </div> -->
                    <!-- <p>Su pedido es: 489616516851</p> -->
                    <div class="sub-box">
                      <div class="grid-50-50">
                        <span>Nombre o Razón Social:</span>
                        <span>{{ orders.fldTaxName }}</span>
                      </div>
                      <div class="grid-50-50">
                        <span>Número de C.I. o NIT:</span>
                        <span>{{ orders.fldTaxNumber }}</span>
                      </div>
                      <div class="grid-50-50">
                        <span>Número de Pedido:</span>
                        <span>{{ orders.fldNumberOrder }}</span>
                      </div>
                      <div class="grid-50-50">
                        <span>Fecha de pedido:</span>
                        <span>{{ orders.fldCreatedAt }}</span>
                      </div>
                    </div>
                </div>
            </div>
          </div>
      </div>
  </section>
  
    <section class="section-b-space cart-section" style="min-height: 95vh; padding-bottom: 0;">
      <div class="mb-4 contact-us">
          <div class="container">
            <div class="card-default list">     
              <div style=" overflow: auto;">
                <table class="table mb-0">
                    <thead>
                        <tr class="table-head">
                            <th scope="col">Producto</th>
                            <th scope="col" class="text-right">Cantidad</th>
                            <th scope="col" class="text-right">Precio Unit. (Bs)</th>
                            <th scope="col" class="text-right">Descuento</th>
                            <th scope="col" class="text-right">Subtotal (Bs)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let product of orders.order_details">
                            <td>
                              <h5>{{ product.item.fldName}} </h5>
                            </td>
                            <td>
                                <h5 class="mb-0 text-end"> {{ product.fldQuantity }} </h5>
                            </td>
                            <td>
                                <h5 class="mb-0 text-end"> {{ product.fldPrice }}</h5>
                            </td>
                            <td>
                                <h5 class="mb-0 text-end"> {{ product.fldDiscountPercent }} % </h5>
                            </td>
                            
                            <td>
                                <h5 class="mb-0 text-end"> Bs. {{ product.fldSubTotalPrice }} </h5>
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td colspan="4">
                                <h3 class="mb-0 text-end">
                                Total
                                </h3>
                            </td>
                            
                            <td>
                                <h5 class="mb-0 text-end">
                                  Bs. {{ orders.fldNetTotal }}
                                </h5>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>     
            </div>
          </div>
      </div>
    </section>
  `,
  styleUrls: [
  ]
})
export class OrderConfirmationComponent implements OnInit {

  get orders(){
    return this.laCascadaProductService.orders;
  }

  constructor(private laCascadaProductService: LaCascadaProductService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params
    .subscribe((resp: any) =>{
      this.laCascadaProductService.getOrderConfirmation(resp.id);
    })
  }

  ngOnInit(): void {
  }

}
