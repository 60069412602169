<!-- side-bar single product slider start -->
<div class="theme-card">
    <h5 class="title-border">{{ title }}</h5>
    <owl-carousel-o [options]="NewProductSliderConfig" class="offer-slider">
        <ng-container *ngFor="let product of productXcategorySlider ">  
            <ng-template carouselSlide>
                <div>
                  <div class="d-flex align-items-center" *ngFor="let product of productXcategorySlider | slice:0:3 ">
                    <div class="product-box">
                      <app-product-la-cascada [favorites]="favorites" [product]="product" class="custom-product" [horizontal]="true">
                      </app-product-la-cascada>
                    </div>
                  </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div>
                  <div class="d-flex align-items-center" *ngFor="let product of productXcategorySlider | slice:3:6 ">
                    <div class="product-box">
                      <app-product-la-cascada [favorites]="favorites" [product]="product" class="custom-product" [horizontal]="true">
                      </app-product-la-cascada>
                    </div>
                  </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div>
                  <div class="d-flex align-items-center" *ngFor="let product of productXcategorySlider | slice:6:9 ">
                    <div class="product-box">
                      <app-product-la-cascada [favorites]="favorites" [product]="product" class="custom-product" [horizontal]="true">
                      </app-product-la-cascada>
                    </div>
                  </div>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>
<!-- side-bar single product slider end -->
  