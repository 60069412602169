<!--footer section -->
<footer [class]="class">
	<!-- <div class="pre-footer-container" *ngIf="router.url != '/home/products'">
		<div class="container">
			<div class="pre-footer">
				<img (click)="changeBrand(1)" src="../../../../assets/la-cascada/brands/VillaSanta.png" alt="logo">
				<img (click)="changeBrand(2)" src="../../../../assets/la-cascada/brands/Viscachani.png" alt="logo">
				<img (click)="changeBrand(3)" src="../../../../assets/la-cascada/brands/AguaSport.png" alt="logo">
				<img (click)="changeBrand(4)" src="../../../../assets/la-cascada/brands/Zanna.png" alt="logo">
				<img (click)="changeBrand(5)" src="../../../../assets/la-cascada/brands/sabores.png" alt="logo">
				<img (click)="changeBrand(6)" src="../../../../assets/la-cascada/brands/CokaQuina.png" alt="logo">
				<img (click)="changeBrand(7)" src="../../../../assets/la-cascada/brands/Jumbo.png" alt="logo">
			</div>
		</div>
	</div> -->
	<section class="light-layout pt-4 pb-4">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-2 col-md-2">
					<div class="footer-contant">
						<div class="footer-logo mb-0">
							<img [src]="themeLogo" alt="logo">
						</div>
						<!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </p> -->
					</div>
				</div>
				<div class="col d-flex align-items-center">
					<div class="sub-title">
						<!-- <div class="footer-title">
                            <h4>Categories</h4>
                        </div> -->
                        <div class="footer-contant">
                            <ul>
                                <li>
									<a [routerLink]="['/home/contact-us']">
										<!-- <i class="fa fa-map-marker"></i>  -->
										Contáctanos
									</a>
								</li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col d-flex align-items-center">
					<div class="sub-title">
						<!-- <div class="footer-title">
                            <h4>why we choose</h4>
                        </div> -->
                        <div class="footer-contant">
                            <ul>
                                <li >
									<a [routerLink]="['/home/products']">Productos</a>
								</li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col d-flex align-items-center">
					<div class="sub-title">
						<!-- <div class="footer-title">
                            <h4>why we choose</h4>
                        </div> -->
                        <div class="footer-contant">
                            <ul>
                                <li >
									<a [routerLink]="['/home/faq']">Preguntas Frecuentes</a>
								</li>
                            </ul>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-9 col-md-9 col-sm-12">
                  <div class="footer-end">
                    <p><!--  {{ today | date:'y'}} --> Desarrollo por Solunes Digital | Todos los derechos reservados | <i class="fa fa-copyright" aria-hidden="true"></i> Grupo Cascada</p>
                  </div>
                </div>
                <div class="col-xl-3 col-md-3 col-sm-12">
                  <div class="footer-social">
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/CascadaBolivia" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                      </li>
                      <li>
                        <a href="https://twitter.com/CascadaBolivia" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/user/CascadaBolivia" target="_blank"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/lacascada.bolivia/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->