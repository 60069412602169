<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <!-- <div class="top-header" *ngIf="hide && laCascadaProductService.guestFlag == '0'">
    <div class="container patch">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li>Descuento del 10% en tu primera compra, haz tu pedido con el cupón  <b>“CUPON1”</b></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 text-end">
          <ul class="header-dropdown">
            <li (click)="hideMenu()">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div> -->
  <div class="container patch">
    <div class="row" style="z-index: 11; position: relative;">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <!-- <app-left-menu></app-left-menu> -->
            <div class="brand-logo" style="max-width: 60px;">
              <a routerLink="" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid" alt="logo">
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
            </div>
            
            <div class="header-select-container desktop" *ngIf="router.url != '/home/location'">
              <img [src]="checkCity(1)" alt="icon"> 
              <select class="header-select" id="header-select-2" (change)="changeCity()">
                <option *ngFor="let item of cities" [selected]="checkCity(0) == item.pkyCity" [value]="item.pkyCity">{{item.name}}</option>
              </select>
            </div>

            <div class="patch-icons-menu-inner">
              <app-settings></app-settings>
            </div>

            <div class="desktop" style="margin-top: 10px; padding-left: 1.6rem">
              <a [class.d-none]="this.laCascadaProductService.guestFlag == '0'" [routerLink]="['/home/login']" class="btn btn-primary btn-header desktop">Iniciar Sesión</a>
              <!-- <img *ngIf="!userInformation.firstName" src="../../../../assets/la-cascada/icons/user_white.svg" [routerLink]="['/home/login']" class="mobile" alt="icon"> -->
              <ul [class.d-none]="this.laCascadaProductService.guestFlag == '1'" class="onhover-dropdown mobile-account btn btn-header login-list">
                <!-- <li class="btn-login mobile">
                  <img src="../../../../assets/la-cascada/icons/user_white.svg" class="mobile" alt="icon">
                </li> -->
                <li class="desktop btn-login">
                  <img src="../../../../assets/la-cascada/icons/user.svg" *ngIf="!userInformation?.urlImage" alt="icon">
                  <img class="img-user" [src]="userInformation.urlImage" alt="icon" *ngIf="userInformation?.urlImage">
                  {{ sliceName(userInformation.firstName) }}, {{ userInformation.lastName?.slice(0, 1) }}.
                </li>
                <ul class="onhover-show-div">
                  <li routerLink="/home/terms-and-conditions" (click)="laCascadaProductService.searchToggleOff()">
                    <a> Términos y condiciones </a>
                  </li>
                  <li routerLink="/home/about-us" (click)="laCascadaProductService.searchToggleOff()">
                    <a> Sobre nosotros </a>
                  </li>
                  <li routerLink="/home/sign-in" (click)="laCascadaProductService.searchToggleOff()">
                    <a> Editar información </a>
                  </li>
                  <li routerLink="/home/order-history" (click)="laCascadaProductService.searchToggleOff()">
                    <a> Historial de pedidos </a>
                  </li>
                  <li (click)="logOut()" (click)="laCascadaProductService.searchToggleOff()">
                    <a> Cerrar sesión </a>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div id="search-overlay" class="" [ngStyle]="{'display': laCascadaProductService.search ? 'block' : 'none' }">
        <div> 
            <!-- <span class="closebtn" title="Close Overlay" (click)="laCascadaProductService.searchToggle()">×</span> -->
            <div class="overlay-content">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12 collection-collapse-block pb-3 pt-1 pt-0">
                            <form [formGroup]="formSearch" class="d-flex gap-2 w-100">
                              <div class="input-wrapper mb-0 w-100 d-flex gap-2">
                                <input type="text" placeholder="Buscar producto por nombre.." formControlName="search" (keyup)="laCascadaProductService.searchProduct(formSearch.get('search').value)">
                                <!-- <button class="btn btn-primary btn-search" href>Filtros</button> -->
                              </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</header>
