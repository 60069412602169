import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { LaCascadaProductService } from '../../services/la-cascada-product.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public menuItems: Menu[];

  constructor(public router: Router, public navServices: NavService, public laCascadaProductService: LaCascadaProductService) {
    this.navServices.items.subscribe(menuItems => this.menuItems = menuItems );
    // this.router.events.subscribe((event) => {
    //   this.navServices.mainMenuToggle = false;
    // });
  }

  ngOnInit(): void {
  }

  get cities(): any[]{
    return this.laCascadaProductService.cities;
  }

  get userInformation(){
    return this.laCascadaProductService.userInformation;
  }

  mainMenuToggle(): void {
    if(window.screen.width < 1199){
      this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
      var backoutMenu = document.getElementById('blackout-mobile-menu');

      if(this.navServices.mainMenuToggle){
        backoutMenu.classList.add('visual-active');
        backoutMenu.classList.add('active');
      }else{
        backoutMenu.classList.remove('visual-active');
        setTimeout(() => {
          backoutMenu.classList.remove('active');
        }, 200);
      }
    }
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

  redirect(value: string){
    // this.laCascadaProductService.changeCategory$.next(value);
    // var localValue = Number(value) -1 ;

    // this.laCascadaProductService.flagHeaderFilter[1] = false;
    // this.laCascadaProductService.flagHeaderFilter[0] = false;
    // this.laCascadaProductService.categorySelected = value;
    // localStorage.setItem('categorySelectedName', this.laCascadaProductService.categorySelectedName)
    // localStorage.setItem('selectedCategory', value);
    // this.laCascadaProductService.getProductXCityXCategory( this.laCascadaProductService.citySelected[0] , value, '');
    // this.laCascadaProductService.searchProductResults = [];

    // for (let index = 0; index < this.laCascadaProductService.filterStorageUnits.length; index++) {
    //   this.laCascadaProductService.filterStorageUnits[index] = false;
    // }
    
    // this.laCascadaProductService.actualBrand = 0;
    // this.laCascadaProductService.filtersBrands = [];
    // this.laCascadaProductService.showBanner = false;
    // localStorage.setItem('categorySelectedName', this.laCascadaProductService.categorySelectedName)
    // this.laCascadaProductService.searchFlag[0] = false;
    // this.laCascadaProductService.showOriginal = true;
  }

  changeCity(){
    var e = <HTMLInputElement>document.getElementById("header-select");
    var localData: string[] = []; 
    localData[0] = e.value ;
    this.cities.forEach(element => {
      if(element.pkyCity == localData[0]){
        localData[1] = element.urlImage;
      }
    });

    localStorage.setItem( 'selectedCity', JSON.stringify(localData) );
    this.laCascadaProductService.citySelected = localData;
    
    window.location.reload();
  }

  logOut(){
    this.laCascadaProductService.postLogOut({ userId: this.userInformation.pkyBill });
  }

}
