<div class="theme-card">
    <div class="d-flex justify-content-between title-border">
      <h5>Su carrito de compras</h5>
      <div class="loader-min" id="loader-cart">
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
    </div>
    <div class="content-border">
        <p class="text-center mb-2 mt-2">Compra mínima Bs. 20 | Envíos sin costo</p>
        <div class="content-product-wrapper" *ngFor="let product of shoppingCart?.storage_cart_detail; let i = index">
            <div class="grid-item">
                <a routerLink="/home/product/{{product.storage_item.pkyItem}}" (click)="redirectProduct(product)">
                    <img 
                        [defaultImage]="'assets/images/product/placeholder.jpg'" 
                        [lazyLoad]="product.storage_item.url_image" 
                        [alt]="product.fldName" 
                        class="img-fluid lazy-loading" />
                </a>
                <div>
                    <h5>{{ product.storage_item.fldName | titlecase }} <br> {{ product.storage_item.cartSummary | titlecase }}</h5>
                    <div class="media-body">
                        <div class="qty-box">
                            <div class="input-group">
                                <span class="input-group-prepend">
                                    <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="removeProductValue(i)">
                                        <i class="ti-angle-left"></i>
                                    </button>
                                </span>
                                <input type="text" #quantity id="quantity-{{i}}" class="form-control" disabled [value]="product.fldQuantity">
                                <span class="input-group-prepend">
                                    <button type="button" class="btn quantity-right-plus"  data-type="plus" (click)="addProductValue(i)">
                                        <i class="ti-angle-right"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column">
                  <h5 class="text-end mb-1">Precio Unit. por paquete</h5>
                    <h5 class="mb-0 price muted-color tached" *ngIf="product.fldPrice != product.fldTotalNetPrice">
                        Bs. {{product.fldPrice * product.fldQuantity | number : '1.2-2'}}
                    </h5>
                    <h5 class="mb-0 price" *ngIf="laCascadaProductService.guestFlag == '1'">
                      Bs. {{ (product.fldPrice - product.fldDiscountAmount) * product.fldQuantity | number : '1.2-2' }}
                    </h5>
                    <h5 class="mb-0 price" *ngIf="laCascadaProductService.guestFlag == '0'">
                        <!-- Bs {{product.fldTotalNetPrice}} -->
                        Bs. {{product.fldSubTotalPrice}}
                    </h5>
                    <a (click)="removeCart(product.pkyCartDetail)" href="javascript:void(0)" class="icon">
                        <img src="../../../assets/la-cascada/icons/trash.svg" class="table-icon">
                    </a>
                </div>
            </div>
            
        </div>
        
        <div class="product-container-wrapper" style="padding: 3% 10%">
            <div class="section-divider justify-content-center">
                <p class="mb-0 mb-2 mt-0" *ngIf="suggestedProducts.length != 0"><b>Te puede interesar</b></p>

                <!-- <div class="divider-inner">
                  <div class="filter small active">
                    <div class="filter-image-wrapper">
                      <div class="filter-inner" [ngStyle]="{'background-color': categories[0]?.fldColor}"></div>
                      <img [src]="categories[0]?.urlImage" alt="icon">
                    </div>
                  </div>
                </div> -->
                <!-- <a [routerLink]="['/home/products']" (click)="changeCategory(categories[0]?.pkyFamily)">Ver todo</a> -->
              </div>
          <!-- <div class="section-divider">
            <div class="divider-inner">
              <img src="../../../assets/la-cascada/sections/offer-min.png" alt="icon">
              <h3>Te puede interesar</h3>
            </div>
            <a [routerLink]="['/home/products']" (click)="changeCategory('1')">Ver todo</a>
          </div> -->
          
          <section class="section-b-space p-t-0 p-b-0">
            <div class="container">
              <div class="row">
                <div class="col">
                  <owl-carousel-o class="product-m" [options]="ProductSliderConfig">
                    <ng-container *ngFor="let product of suggestedProducts">
                      <ng-template carouselSlide>
                        <div class="product-box">
                          <app-product-la-cascada [favorites]="favorites" [product]="product" class="custom-product carousel" [horizontal]="true">
                          </app-product-la-cascada>
                        </div>
                      </ng-template>
                    </ng-container>
                  </owl-carousel-o>
                </div>
              </div>
            </div>
          </section>
        </div>

    </div>
    <div class="bottom-border" routerLink="/home/shopping-cart-details" (click)="nextShopping()">
        <h5 style="line-height: 1.2;">
          <small>Total compra :  </small>  
            <small *ngIf="laCascadaProductService.guestFlag == '0'">Bs. {{ shoppingCart?.fldNetTotal }}</small> 
            <small *ngIf="laCascadaProductService.guestFlag == '1'">Bs. {{ totalCart | number : '1.2-2' }}</small>
            
            <br>
            
            <small>Continuar con el pedido</small>
            <img src="../../../assets/la-cascada/icons/chevron-right.svg" alt="icon"> 
        </h5>
    </div>
</div>

