<div class="blackout-mobile-menu" id="blackout-mobile-menu"></div>
<div class="main-navbar">
  <div id="mainnav">
    <div class="toggle-nav" (click)="mainMenuToggle()">
      <i class="fa fa-bars sidebar-bar"></i>
    </div>
    <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">

      <li class="back-btn">
        <div class="mobile-back text-end" (click)="mainMenuToggle()">
          <span>Atras</span>
          <i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
        </div>
      </li>

      <div class="mobile" style="margin-top: 10px; padding-left: .5rem">
        <a [class.d-none]="this.laCascadaProductService.guestFlag == '0'" [routerLink]="['/home/login']" class="btn btn-primary btn-header mobile" (click)="mainMenuToggle()">Iniciar Sesión</a>
        <!-- <img *ngIf="!userInformation.firstName" src="../../../../assets/la-cascada/icons/user_white.svg" [routerLink]="['/home/login']" class="mobile" alt="icon"> -->
        <ul [class.d-none]="this.laCascadaProductService.guestFlag == '1'" class="onhover-dropdown mobile-account btn btn-header login-list">
          <!-- <li class="btn-login mobile">
            <img src="../../../../assets/la-cascada/icons/user_white.svg" class="mobile" alt="icon">
          </li> -->
          <li class="desktop btn-login">
            <img src="../../../../assets/la-cascada/icons/user.svg" *ngIf="userInformation?.urlImage?.split('/')[userInformation?.urlImage?.split('/').length - 1] == ''" alt="icon">
                  <img class="img-user" [src]="userInformation.urlImage" alt="icon" *ngIf="userInformation?.urlImage?.split('/')[userInformation?.urlImage.split('/').length - 1] != ''">
            {{ userInformation.firstName }}, {{ userInformation?.lastName?.slice(0, 1) }}.
          </li>
          <ul class="onhover-show-div">
            <li routerLink="/home/terms-and-conditions" class="text-center" (click)="mainMenuToggle()">
              <a> Términos y condiciones </a>
            </li>
            <li routerLink="/home/about-us" class="text-center" (click)="mainMenuToggle()">
              <a> Sobre nosotros </a>
            </li>
            <li routerLink="/home/sign-in" class="text-center" (click)="mainMenuToggle()">
              <a> Editar información </a>
            </li>
            <li routerLink="/home/order-history" class="text-center" (click)="mainMenuToggle()">
              <a> Historial de pedidos </a>
            </li>
            <li (click)="logOut()" class="text-center" (click)="mainMenuToggle()">
              <a> Cerrar sesión </a>
            </li>
          </ul>
        </ul>
      </div>

      <div class="header-select-container mobile" *ngIf="router.url != '/home/location'">
        <img [src]="laCascadaProductService.citySelected[1]" alt="icon"> 
        <select class="header-select" id="header-select" (change)="changeCity()">
          <option *ngFor="let item of cities" [selected]="laCascadaProductService.citySelected[0] == item.pkyCity" [value]="item.pkyCity">{{item.name}}</option>
        </select>
      </div>

      <li *ngFor="let menuItem of menuItems" [class.mega-menu]="menuItem.megaMenu">
        <!-- Sub -->
        <a href="javascript:void(0)" class="nav-link" *ngIf="menuItem.type === 'sub'" 
            (click)="toggletNavActive(menuItem)">
          {{ menuItem.title | translate }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>
        <!-- Link -->
        <a [routerLink]="menuItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link" (click)="mainMenuToggle()"
          *ngIf="menuItem.type === 'link'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>
        <!-- External Link -->
        <a href="{{ menuItem.path }}" class="nav-link" *ngIf="menuItem.type === 'extLink'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>
        <!-- External Tab Link -->
        <a href="{{ menuItem.path }}" class="nav-link" *ngIf="menuItem.type === 'extTabLink'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>

        <!-- 2nd Level Menu -->
        <ul class="nav-submenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.children">
          <li *ngFor="let childrenItem of menuItem.children">
            <!-- Sub -->
            <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" 
                (click)="toggletNavActive(childrenItem)">
              {{ childrenItem.title | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            <!-- Link -->
            <!-- sub link menu -->
            <a [routerLink]="childrenItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
              *ngIf="childrenItem.type === 'link' " (click)="mainMenuToggle()">
              {{ childrenItem.title | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            <!-- External Link -->
            <a href="{{ childrenItem.path }}"
              *ngIf="childrenItem.type === 'extLink' ">
              {{ childrenItem.title | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            <!-- External Tab Link -->
            <a href="{{ childrenItem.path }}" target="_blank"
              *ngIf="childrenItem.type === 'extTabLink' ">
              {{ childrenItem.title | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>

            <!-- 3rd Level Menu -->
            <ul *ngIf="childrenItem.children" [class.opensubchild]="childrenItem.active" class="nav-sub-childmenu">
              <li *ngFor="let childrenSubItem of childrenItem.children">
                <!-- Link -->
                <a [routerLink]="childrenSubItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" 
                  *ngIf="childrenSubItem.type === 'link' ">
                  {{ childrenSubItem.title | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
                <!-- External Link -->
                <a href="{{ childrenSubItem.path }}"
                  *ngIf="childrenSubItem.type === 'extLink' ">
                  {{ childrenSubItem.title | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
                <!-- External Tab Link -->
                <a href="{{ childrenSubItem.path }}" target="_blank"
                  *ngIf="childrenSubItem.type === 'extTabLink' ">
                  {{ childrenSubItem.title | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>

        <div class="mega-menu-container" *ngIf="menuItem.megaMenu && menuItem.children">
          <div class="container">
            <div class="row">
              <div class="col mega-box" *ngFor="let childrenItem of menuItem.children">
                <div class="link-section">
                  <div class="menu-title">
                    <h5>
                        {{ childrenItem.title | translate }}
                        <span class="sub-arrow" *ngIf="childrenItem.children"></span>
                    </h5>
                  </div>
                  <div class="menu-content opensubmegamenu">
                    <ul *ngIf="childrenItem.children">
                      <li *ngFor="let childrenSubItem of childrenItem.children">
                        <!-- Sub -->
                          <a href="javascript:void(0)" *ngIf="childrenSubItem.type === 'sub'">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                          <!-- Link -->
                          <a [routerLink]="childrenSubItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" 
                            *ngIf="childrenSubItem.type === 'link' ">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                          <!-- External Link -->
                          <a href="{{ childrenSubItem.path }}"
                            *ngIf="childrenSubItem.type === 'extLink' ">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                          <!-- External Tab Link -->
                          <a href="{{ childrenSubItem.path }}" target="_blank"
                            *ngIf="childrenSubItem.type === 'extTabLink' ">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
