import { Component, OnInit } from '@angular/core';
import { Product } from "../../classes/product";
import { LaCascadaProductService } from '../../services/la-cascada-product.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public products: Product[] = [];
  public loader = document.getElementById('loader-full');
  
  constructor(private toastrService: ToastrService ,public laCascadaProductService: LaCascadaProductService, public router: Router) {
    this.laCascadaProductService.getFavorites();
    if (laCascadaProductService.guestFlag == '0') {
      this.loader.classList.add('show');

      this.laCascadaProductService.getNotifications(this.userInformation.pkyBill)
      .subscribe( ( resp :any )=>{
        
        this.laCascadaProductService.notifications = resp.items.data;

        this.laCascadaProductService.notifications_to_show = [];
        this.laCascadaProductService.notifications.forEach(element => {
          
          if(element.fldIsSent == false){
            this.laCascadaProductService.notifications_to_show.push(element);
            this.toastrService.info(`${element.fldName}`, `${element.fldMessage}`, {timeOut: 5000} );
          }
          this.loader.classList.remove('show');
        });      
      });
    }
  }

  ngOnInit(): void {
    
  }

  get userInformation(){
    return this.laCascadaProductService.userInformation;
  }

  get shoppingCart(): any {
    return this.laCascadaProductService.shoppingCart;
  }

  get favorites(): any[] {
    return this.laCascadaProductService.favorites;
  }

  compareWindowLocation(path: string){
    return window.location.href.indexOf(`${path}`) > -1;
  }

  markAsReaded(id: number, type: string){
    var noti: any = {
      pkyNotification: [id],
      fldIsSent: true
    }
    this.laCascadaProductService.postNotificationsReaded(noti);

    for (let index = 0; index < this.laCascadaProductService.notifications_to_show.length; index++) {
      if(this.laCascadaProductService.notifications_to_show[index].pkyNotification == id){
        this.laCascadaProductService.notifications_to_show.splice(index, 1)
      }
    }
    if(type == "notification"){
      this.router.navigate(['home/order-history']);
    }
  }

  hideShoppingWidget(){
    if(!this.compareWindowLocation('shopping-cart-details') && !this.compareWindowLocation('location')){
      if(!this.laCascadaProductService.objetEmpty(this.shoppingCart)){
        this.laCascadaProductService.hideShoppingWidget();
      }else{
        this.toastrService.info(`Tu carrito de compras está vacío.`, '', {timeOut: 5000} );
      }
    }
    
  }
}
