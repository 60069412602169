import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CartModalComponent } from '../../modal/cart-modal/cart-modal.component';
import { QuickViewComponent } from '../../modal/quick-view/quick-view.component';
import { ProductLaCascada } from '../../../classes/product-la-cascada';
import { LaCascadaProductService } from '../../../services/la-cascada-product.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-la-cascada',
  templateUrl: './product-la-cascada.component.html',
  styleUrls: ['./product-la-cascada.component.scss']
})
export class ProductLaCascadaComponent implements OnInit {

  @Input() product: any;
  // @Input() thumbnail: boolean = false; // Default False 
  // @Input() onHowerChangeImage: boolean = false; // Default False
  // @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  @Input() horizontal: boolean = false;

  @Input() favorites: any = [];

  productValue: number = 1;
  showProductValue: boolean = false;
  fav: boolean = false;
  delteCode: number = 0;
  
  public ImageSrc : string;
  discountedPrice: number = 0;

  constructor(private laCascadaProductService: LaCascadaProductService, private toastrService: ToastrService, private router: Router) {
    this.laCascadaProductService.updateFav$
    .subscribe(( resp:boolean )=>{
      if(resp){
        this.reloadFavs();
      }
    });
  }

  ngOnInit(): void {
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
    this.discountedPrice = this.calculateDiscount(this.product.formatPriceDiscount, this.product.formatPrice);
    this.reloadFavs()
    
  }

  reloadFavs(){
    var controlFlag:boolean = false
    this.favorites.forEach(element => {
      if(element.pkyItem == this.product.pkyItem){
        controlFlag = true;
        this.delteCode = element.favouriteId;
      }
    });
    this.fav = controlFlag;
  }

  // get discount percantaje 
  calculateDiscount( price: number, oldPrice: number): number{
    let discount = oldPrice - price;
    discount = (discount * 100)/oldPrice; 
    return Number(discount.toPrecision(3));
  }


  // redirectProduct() {
  //   // window.scroll({ top: 0, left: 0 });
  //   // this.laCascadaProductService.productSelected = this.product;
  //   this.laCascadaProductService.favoriteSelected[0] = this.fav;
  //   this.laCascadaProductService.favoriteSelected[1] = this.delteCode;
  //   // localStorage.setItem( 'selectedProduct', JSON.stringify(this.product));
  //   localStorage.setItem( 'selectedFavorite', JSON.stringify(this.laCascadaProductService.favoriteSelected));
  // }

  get userInformation(){
    return this.laCascadaProductService.userInformation;
  }

  addRemoveFav(product: any){
    var code = product.pkyItem;
    var addParams = { 
      codItem: code,
      codUser: this.userInformation.pkyBill
    }
    var removeParams = { 
      id: this.delteCode
    }  
    
    if(!this.fav){
      if(this.laCascadaProductService.guestFlag == '1'){                
        this.laCascadaProductService.favorites.push(product);            
        localStorage.setItem('LaCascadaFavs', JSON.stringify(this.favorites));
        this.fav = true;
        if(this.laCascadaProductService.product_selected.pkyItem == product.pkyItem){
          this.laCascadaProductService.product_selected_is_favorite = true;
        }
      }else{
        this.laCascadaProductService.postAddFav(addParams)
          .subscribe(( resp: any )=>{
          if(resp.status){
            this.laCascadaProductService.getFavorites();
            // this.toastrService.success(`Producto favorito agregado`, '', {timeOut: 5000});
            this.fav = true;
            this.delteCode = resp.item.pkyItemFavourite;
            if(this.laCascadaProductService.product_selected.pkyItem == product.pkyItem){
              this.laCascadaProductService.product_selected_is_favorite = true;
            }
          }else{
            this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
          }
        });          
      }      
    }else{
      // guest user
      if(this.laCascadaProductService.guestFlag == '1'){        
        for (let index = 0; index < this.laCascadaProductService.favorites.length; index++) {
          if (this.laCascadaProductService.favorites[index].pkyItem == code) {
            this.laCascadaProductService.favorites.splice(index, 1);
            localStorage.setItem('LaCascadaFavs', JSON.stringify(this.favorites));
          }
        }
        this.fav = false;
        if(this.laCascadaProductService.product_selected.pkyItem == product.pkyItem){
          this.laCascadaProductService.product_selected_is_favorite = false;
        }
      }else{
        this.laCascadaProductService.postRemoveFav(removeParams)
        .subscribe(( resp: any )=>{
          if(resp.status){
            this.laCascadaProductService.getFavorites();
            this.fav = false;
            if(this.laCascadaProductService.product_selected.pkyItem == product.pkyItem){
              this.laCascadaProductService.product_selected_is_favorite = false;
            }
          }else{
            this.toastrService.error(`${resp.mensaje}`, '', {timeOut: 5000} );
          }
        });
      }
    }
    
    // if(this.laCascadaProductService.productSelected.pkyItem == this.product.pkyItem){
    //   this.laCascadaProductService.favoriteSelected[0] = this.fav;
    //   this.laCascadaProductService.favoriteSelected[1] = this.delteCode;
    //   localStorage.setItem( 'selectedFavorite', JSON.stringify(this.laCascadaProductService.favoriteSelected));
    // }
  }

  addProductShoppingCart(){
    this.showProductValue = true;
  }

  hideProductShoppingCart(){
    this.showProductValue = false;
  }

  confirmShoppingCart(product: any, quantity: string){
    this.laCascadaProductService.postAddProductShoppingCart({ product: product, fldQuantity: Number(quantity) });
    this.showProductValue = false;
  }

  addProductValue(){
    if(this.productValue < 100){
      this.productValue += 1;
    }
  }
  removeProductValue(){
    if(this.productValue > 1){
      this.productValue -= 1;
    }
  }

}
