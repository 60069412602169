import { Component, OnInit } from '@angular/core';
import { LaCascadaProductService } from '../services/la-cascada-product.service';
import { ProductSliderCart } from '../data/slider';

@Component({
  selector: 'app-shopping-widget',
  templateUrl: './shopping-widget.component.html',
  styleUrls: ['./shopping-widget.component.scss']
})
export class ShoppingWidgetComponent implements OnInit {
  
  public totalCart: number = 0;
  public categories: any = [];
  public ProductSliderConfig: any = ProductSliderCart; 
  public loader = document.getElementById('loader-full');

  constructor(public laCascadaProductService: LaCascadaProductService) {
    // this.loader.classList.add('show');
    // this.laCascadaProductService.getCategoriesToSubscribe(this.laCascadaProductService.citySelected[0])
    //   .subscribe( (resp: any)=>{
    //     this.categories = resp.items.data;

    //     // this.categories.forEach(element => {
    //       this.laCascadaProductService.getProductXCityXCategory(laCascadaProductService.citySelected[0], `${this.categories[0].pkyFamily}`, '');
    //     // });
    //     this.loader.classList.remove('show');
    //   } ) 

    this.laCascadaProductService.updateTotalCart$
        .subscribe(data => {         
          if(data){
            this.updateTotal()
          }
      });
  }

  ngOnInit(): void {
    this.laCascadaProductService.loaderCart = document.getElementById('loader-cart');
  }

  ngAfterContentInit(){
    this.updateTotal();
  }

  get shoppingCart() {   
    return this.laCascadaProductService.shoppingCart;
  }

  get favorites(): any[] {
    return this.laCascadaProductService.favorites;
  }

  get suggestedProducts(): any[] {
    return this.laCascadaProductService.suggestedProducts;
  }

  // get productXcategory() {
  //   return this.laCascadaProductService.productXcategory;
  // }


  removeCart(pkyCartDetail: any){
    this.laCascadaProductService.postRemoveProductShoppingCart({id: pkyCartDetail})
  }

  addProductValue( index: number){
   
    if (this.shoppingCart.storage_cart_detail[index].fldQuantity < 100) {
      this.laCascadaProductService.postUpdateProductShoppingCart(
        { id: this.shoppingCart.storage_cart_detail[index].pkyCartDetail, 
          fldQuantity: this.shoppingCart.storage_cart_detail[index].fldQuantity + 1, 
        }
      )
    }
    if(this.laCascadaProductService.guestFlag == '1'){
      this.updateTotal();
    }
  }
  
  removeProductValue( index: number){
    
    var quatityInput = <HTMLInputElement>document.getElementById(`quantity-${index}`);
    
    if(Number(quatityInput.value) > 1 && Number(quatityInput.value) < 100){
      this.laCascadaProductService.postUpdateProductShoppingCart(
        { id: this.shoppingCart.storage_cart_detail[index].pkyCartDetail, 
          fldQuantity: this.shoppingCart.storage_cart_detail[index].fldQuantity - 1, 
        }
      )
    }else{
      // this.toastrService.error(`La cantidad para ordenar un producto no puede ser menor a 1`, '', {timeOut: 5000} );
    }

    if(this.laCascadaProductService.guestFlag == '1'){
      this.updateTotal();
    }
  }

  redirectProduct(product: any) {
    var fav = false
    var delteCode = 0;
    // var productLocal = product.storage_item;
    // console.log(productLocal);
    this.laCascadaProductService.getSpecificProductXCity(this.laCascadaProductService.citySelected[0], product.codItem);
    
    this.favorites.forEach(element => {
      if(element.pkyItem == product.storage_item.pkyItem)
      {
        fav = true;
        delteCode = element.favouriteId;
      }
    });
    
    // this.laCascadaProductService.favoriteSelected[0] = fav;
    // this.laCascadaProductService.favoriteSelected[1] = delteCode;
    // localStorage.setItem( 'selectedFavorite', JSON.stringify(this.laCascadaProductService.favoriteSelected));
    this.laCascadaProductService.hideShoppingWidget()
  }

  truncNumber(number){    
    return Math.trunc( number );
  }

  changeCategory(value: string) {
    this.laCascadaProductService.categorySelected = value;
    localStorage.setItem('selectedCategory', value);
  }

  nextShopping(){
    this.laCascadaProductService.hideShoppingWidget();
  }

  updateTotal(){   
    this.totalCart = 0;
    this.shoppingCart.storage_cart_detail.forEach(element => {
      this.totalCart  = Math.round((((Number(element.fldPrice) - Number(element.fldDiscountAmount)) * element.fldQuantity)  + this.totalCart + Number.EPSILON) * 100) / 100;
      // this.totalCart = (element.fldTotalNetPrice * element.fldQuantity) + this.totalCart;
    });
    if(this.laCascadaProductService.guestFlag == '1'){
      var productBrand = this.laCascadaProductService._shoppingCart?.storage_cart_detail[this.laCascadaProductService._shoppingCart.storage_cart_detail.length - 1].storage_item.storage_brand.pkyItemBrand;
      
      if(productBrand != undefined){
        this.laCascadaProductService.getSuggestedProducts( Number(this.laCascadaProductService.citySelected[0]), productBrand);
      }
      
    }
  }
}